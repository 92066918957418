body {
  margin: 0;
  /* background-color: #f8f8f8; */
}

main.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  main.container {
    width: 970px;
  }
}

@media (min-width: 768px) {
  main.container {
    width: 750px;
  }
}